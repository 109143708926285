import React, { Component } from 'react'
import Gallery from 'react-grid-gallery';
import Example from '../component/RecentNews';
import TitleStyle from '../component/TitleStyle';
import { Container } from '@material-ui/core';
import { PostData } from '../api/service';

const IMAGES =
  [
    {
      src: "https://dummyimage.com/vga",
      thumbnail: "https://dummyimage.com/vga",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Dummy Images"
    },


  ]

export default class FacilityContainer extends Component {

  state = {
    files: [],
    isLoaded: false,
    isDownloading: false
  }
  componentDidMount() {
    // fetch files with code AC

    PostData(`/getfilestoresbytype`, { type: this.props.type })
      .then((resp) => {
        //console.log(resp)
        this.setState({
          files: resp,
          isLoaded: true
        })
      })
  }


  arrangeFiles = (photos) => {
    let pp = [];
    if(Array.isArray(photos)){
      photos.map((el,index) => {
        pp.push({
          src: el.url,
          thumbnail: el.url,
          thumbnailWidth: 320,
          thumbnailHeight: 174,
          caption: el.name
        })
      })
    }

    return pp;
  }

  render() {
    return (
      <div style={{ height: '100vh' }}>
        <Example bread={`Home / ${this.props.title}`} />

        <Container>
          <br />
          <TitleStyle
            title={this.props.title}
          />

          <br />
          {this.state.isLoaded && <div>
            {Array.isArray(this.state.files) && this.state.files.map((el, index) =>
              <div key={index}>
                <div style={{fontWeight: 700, fontSize: 16, marginBottom: 15}}>{el.name}</div>
                <Gallery images={this.arrangeFiles(el.file)} />
              </div>
            )}
          </div>}

        </Container>
      </div>
    )
  }
}
